import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import Container from '../../../bootstrap/Container';
import { StyledHeading, StyledSubHeading, StyledTours, LoadMoreBtn, LoaderContainer } from "./Tours.styles";
import { ErrorMsg } from "../../../pages/Booking/Booking.styles";
import ToursCard from '../ToursCard';
import { setTourGroupDetails } from "../../../redux/action/tourGroupsAction";
import { Loader } from '../../../bootstrap';

const Tours = ({ heading, subHeading, showLoadMoreButton }) => {
  const toursLimit = 8;
  const dispatch = useDispatch();
  const { webSettingData } = useSelector((state) => state.busLists);
  const [tours, setTours] = useState([]);
  const [totalTours, setTotalTours] = useState(null);
  const [page, setPage] = useState(1);
  const [loding, setLoading] = useState(true);

  const getTours = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/tours/package?page=${page}&pageSize=${toursLimit}`);
      const result = await response.json();
      if (result.totalCount > 0) {
        const newTours = tours.concat(result.data);
        setTours(newTours);
      } else {
        setTours([]);
      }
      setLoading(false)
      setTotalTours(result.totalCount);
    } catch (error) {
      setLoading(false)
      console.error("Tours error", error);
    }
  }

  useEffect(() => {
    getTours();
  }, [page])

  const viewMoreTours = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleTourGroupDetails = (tour) => {
    dispatch(setTourGroupDetails(tour));
  };

  return (
    <Container>
      {heading && <StyledHeading>{heading}</StyledHeading>}

      {subHeading && <StyledSubHeading>{subHeading}</StyledSubHeading>}

      <StyledTours>
        {tours?.length === 0 ?
          !loding ? <ErrorMsg>
            Sorry no tours found.
          </ErrorMsg> : <LoaderContainer ><Loader /></LoaderContainer>
          :
          tours?.map(tour => {
            return (
              <NavLink
                key={tour?.tour_id}
                onClick={() => handleTourGroupDetails(tour)}
                to={{
                  pathname: `/tours/${tour?.country_id}`,
                  state: {
                    thumbnail: tour?.thumbnail,
                    tour_name: tour?.tour_name,
                  }
                }}>
                <ToursCard
                  key={tour?.tour_id}
                  image={tour?.thumbnail}
                  title={`${tour?.country_name} packages`}
                />
              </NavLink>
            )
          })
        }
      </StyledTours>

      {
        showLoadMoreButton
        && tours?.length > 0
        && tours?.length !== totalTours
        && <LoadMoreBtn LoadMoreBtn
          onClick={() => viewMoreTours()}
          btnbgcolor={webSettingData?.buttoncolor}
          btnbghvcolor={webSettingData?.buttoncolorhover}
          btntextcolor={webSettingData?.buttontextcolor}
        >view more</LoadMoreBtn>
      }
    </Container >
  );
}

export default Tours;